import React from 'react';
import {withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column'
    },
    comment: {
        fontSize: 22,
        paddingBottom: 10
    }
}));

const TermsOfService = () => {
    const classes = useStyles();

    return (
        <div>
            <h1>
                Terms of Service ("Terms")
            </h1>
            <p>
                We may terminate or suspend access to URL Comments if any of these terms are broken.
            </p>
            <h2>Terms</h2>
            <ul>
                <li>
                    <p>
                        No <a target={"_blank"} href={"https://en.wikipedia.org/wiki/Spamming"}>
                        spamming
                    </a> allowed.
                    </p>
                </li>
                <li>
                    <p>
                        No <a target={"_blank"} href={"https://en.wikipedia.org/wiki/Denial-of-service_attack"}>
                        DOS attacks
                    </a> allowed.
                    </p>
                </li>
                <li>
                    <p>No violent threats or violent calls to action allowed.</p>
                </li>
                <li>
                    <p>
                        No <a target={"_blank"} href={"https://en.wikipedia.org/wiki/Personal_data"}>
                        personal data
                    </a> allowed.
                    </p>
                </li>
            </ul>
        </div>
    );
};

export default withRouter(TermsOfService);
