import React from 'react';
import {withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column'
    },
    comment: {
        fontSize: 22,
        paddingBottom: 10
    }
}));

const ResponseResponses = () => {
    const classes = useStyles();

    return (
        <div>
            <h1>Privacy Policy</h1>
            <h2>Overview</h2>
            <p>
                URL Comments is a Google Chrome extension with the ID <code>oemopgpfffpbigabehblnnnjomiaimlj</code> and
                a set of APIs the extension interacts with. This page is used to inform users regarding our policies
                with the collection, use, and disclosure of data if anyone decided to use our Service, URL Comments.
            </p>
            <h2>Data Collected</h2>
            <p>
                If you post a comment using URL Comments then we will store the URL of the browser's active tab, the
                comment, and the current datetime. The URL, comment and datetime will be publicly accessible. When a
                user opens URL Comments the active tab's URL is passed to our API; URL Comments does NOT store or log
                the URL for these requests.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically
                for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These
                changes are effective immediately, after they are posted on this page.
            </p>
        </div>
    );
};

export default withRouter(ResponseResponses);
