import React from 'react';
import {Route} from 'react-router-dom';
import Homepage from './pages/Homepage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const Router = () => {
    return (
        [
            <Route exact path='/' component={Homepage} key={"Homepage"}/>,
            <Route exact path='/privacy-policy' component={PrivacyPolicy} key={"PrivacyPolicy"}/>,
            <Route exact path='/terms-of-service' component={TermsOfService} key={"TermsOfService"}/>
        ]
    )
};

export default Router;