import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinkIcon from '@material-ui/icons/OpenInNew';
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column'
    },
    comment: {
        fontSize: 22,
        paddingBottom: 10
    },
    noOverflow: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    img: {
        width: "100%",
        maxWidth: 1280
    }
}));

const Homepage = () => {
    const classes = useStyles();
    const [recentComments, setRecentComments] = useState([]);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/recent-comments`, {
            method: "get",
            mode: 'cors',
        })
            .then(res => res.json())
            .then((response) => {
                setRecentComments(response.comments);
            })
            .catch(console.log);

    }, []);

    return (
        <div>
            <h1>URL Comments</h1>
            <p>Read and leave comments about any page on the internet.</p>
            <div><img className={classes.img} alt={"URL Comments extension popup"} src={"/screenshot.png"}/></div>
            <p>Download the URL Comments Google Chrome extension from the Google Chrome Store.</p>
            <Button target={"_blank"}
                    variant="outlined"
                    color="primary"
                    href={"https://chrome.google.com/webstore/detail/url-comments/oemopgpfffpbigabehblnnnjomiaimlj?hl=en"}>
                Visit Google Chrome Store
            </Button>
            <h2>Recent Comments</h2>
            <List component="nav" aria-label="main mailbox folders">
                {
                    recentComments.map(comment => {
                        const href = `${comment.protocol}//${comment.hostname}${comment.pathname}${comment.search}${comment.hash}`;
                        return <ListItem key={comment.id}>
                            <ListItemIcon>
                                <IconButton edge="end" aria-label="link"
                                            href={href}
                                            target={"_blank"}>
                                    <LinkIcon/>
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText
                                primary={`${comment.comment}`}
                                secondary={
                                    <div className={classes.noOverflow} title={moment(comment.datetime).toDate()}>
                                        {`${moment(comment.datetime).from(moment())} on ${comment.hostname}`}
                                    </div>
                                }
                            />
                        </ListItem>
                    })
                }
            </List>
            <div><Link to={"/privacy-policy"}>Privacy Policy</Link></div>
            <div><Link to={"/terms-of-service"}>Terms of Service</Link></div>
        </div>
    );
};

export default withRouter(Homepage);
